<template>
    <b-overlay :show="loading">
        <b-card>
            <b-row class="mb-2">
                <b-col cols="12" classs="mb-2">
                    <b-button variant="primary" class="mb-2" @click.prevent="openAssetModal">Pilih Aset</b-button>
                </b-col>
                <b-col sm="12" md="6" lg="4" class="d-flex align-items-center">
                    <span class="d-block mr-1">Tanggal Disposal</span>
                    <b-form-input type="date" v-model="form.tanggal"></b-form-input>
                </b-col>
            </b-row>
            <b-table
                v-if="selectedPenyimpanans.length > 0"
                striped
                hover
                responsive
                :items="selectedPenyimpanans"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"   
            >
                <template #cell(no)="{index}">
                    {{ ++index }}
                </template>
                <template #cell(nama)="{item}">
                    {{ item.asset ? item.asset.nama : '-' }}
                </template>
                <template #cell(jumlah)="{item}">
                    <b-form-input v-model="item.jumlah" type="number"></b-form-input>
                </template>
                <template #cell(keterangan)="{item}">
                    <b-form-textarea v-model="item.keterangan"></b-form-textarea>
                </template>
                <template #cell(action)="{index}">
                    <feather-icon icon="XCircleIcon" class="text-danger cursor-pointer" size="24" @click.prevent="selectedPenyimpanans.splice(index, 1)"></feather-icon>
                </template>
            </b-table>
            <div class="alert alert-warning p-1" v-else>
                Harap pilih aset yang akan dimasukan ke disposal.
            </div>
            <br>
            <div class="d-flex justify-content-end">
                <b-button variant="primary" :disabled="!isValidForm" @click.prevent="submit">Simpan</b-button>
            </div>
        </b-card>

        <penyimpanan-modal @submit="onChooseAsset"></penyimpanan-modal>
    </b-overlay>
</template>
<script>
import {BOverlay, BCard, BRow, BCol, BFormInput, BFormTextarea, BButton, BTable} from 'bootstrap-vue'
import PenyimpananModal from './components/Modal.vue'
export default {
    components: {
        BOverlay, BCard, BRow, BCol, BFormInput, BFormTextarea, BButton, BTable, PenyimpananModal
    },
    computed: {
        isValidForm() {
            if(this.selectedPenyimpanans.length < 1 || !this.form.tanggal) {
                return false
            }
            const thereZeroJumlah = this.selectedPenyimpanans.some(item => item.jumlah < 1)
            if(thereZeroJumlah) {
                return false
            }

            return true
        }
    },
    data: () => ({
        perPage: 10,
        pageOptions: [10, 30, 50],
        totalRows: 1,
        currentPage: 1,
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
        tanggal: null,
        selectedPenyimpanans: [],
        fields: [
            {key: 'no', label: 'No'},
            {key: 'nama', label: 'Aset'},
            {key: 'jumlah', label: 'Jumlah'},
            {key: 'keterangan', label: 'Keterangan'},
            {key: 'action', label: 'Aksi'}
        ],
        loading: false,
        form: {
            tanggal: null
        }
    }),
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        openAssetModal() {
            this.$bvModal.show('form-disposal')
        },
        onChooseAsset(selectedAssets) {
            selectedAssets.map(item => this.selectedPenyimpanans.push(item))
            this.$bvModal.hide('form-disposal')
        },
        async getPenyimpanans() {
            this.loading = true
            const params = {order: 'desc'}
            if(this.myGudang) params.gudang_id = this.myGudang.id
            await this.$store.dispatch('asset/getStock', params)
            this.loading = false
        },
        async submit() {
            if(!this.isValidForm) {
                this.displayError({
                    message: 'Jumlah aset jangan ada yang 0'
                })
                return false
            }

            const payloads = []
            this.selectedPenyimpanans.map(item => {
                payloads.push({
                    penyimpanan_id: item.id,
                    jenis: this.$route.meta.jenis,
                    tanggal: this.form.tanggal,
                    jumlah: item.jumlah,
                    keterangan: item.keterangan
                })
            })

            try {
                this.loading = true
                await this.$store.dispatch('asset/saveDisposal', payloads)
                this.displaySuccess({
                    message: 'Disposal berhasil disimpan!'
                })
                this.loading = false
                setTimeout(() => this.$router.push(`${this.$route.meta.parent}`), 1000) 
            }
            catch(e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        }
    },
    created() {
        this.form.tanggal = this.getCurrentDate()
        this.getPenyimpanans()
    }
}
</script>